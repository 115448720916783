@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;

  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}
input::-ms-reveal,
input::-ms-clear,
input::-webkit-clear-button {
  display: none;
}

.list-group-item {
  .card-title {
    font-size: 19px;
    color: black;
    cursor: pointer;
    margin-left: 10px;
  }
  .card-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  h2 {
    margin-top: 5px !important;
  }
}

.custom-scrollbar {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #747373 rgb(189, 193, 193);
  border-radius: 10px;

  /* Firefox rgb(110, 110, 238)  #888*/
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* .custom-scrollbar {
  @apply scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-blue-500;
}  */

@keyframes dropTop {
  0% {
    transform: translateY(0%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

.hide-scrollbar {
  overflow: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate-animation {
  to {
    --angle: 360deg;
  }
}

.bg-animation-orange:after {
  content: '';
  --angle: 0deg;
  border-radius: 78px;
  @apply absolute inset-0 z-0 bg-gradient-to-r from-[##FF4E17] via-[#000000] to-[#000000];
}

.bg-animation-purple:after {
  content: '';
  --angle: 0deg;
  border-radius: 78px;
  @apply absolute inset-0 z-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500;
}

.bg-animation-green:after {
  content: '';
  --angle: 0deg;
  border-radius: 78px;
  @apply absolute inset-0 z-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500;
}

.bg-animation-pink:after {
  content: '';
  --angle: 0deg;
  border-radius: 78px;
  @apply absolute inset-0 z-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500;
}

.bg-animation-orange:after {
  animation: rotate-animation 6s linear infinite;
  background: linear-gradient(var(--angle), #ff4e17, #000000, #000000, #000000);
}

.bg-animation-purple:after {
  animation: rotate-animation 6s linear infinite;
  background: linear-gradient(var(--angle), #7d40ff, #000000, #000000, #000000);
}

.bg-animation-green:after {
  animation: rotate-animation 6s linear infinite;
  background: linear-gradient(var(--angle), #d7ff01, #000000, #000000, #000000);
}

.bg-animation-pink:after {
  animation: rotate-animation 6s linear infinite;
  background: linear-gradient(var(--angle), #ff249d, #000000, #000000, #000000);
}

.blur-background {
  filter: blur(5px);
}

.background-container-orange {
  background-image: url('/icons/card.svg');
  /* Add your image path */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  border-radius: 12px;
  /* Set the height to the viewport height or adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-container-purple {
  background-image: url('/icons/card_purple.svg');
  /* Add your image path */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  border-radius: 12px;
  /* Set the height to the viewport height or adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-container-green {
  background-image: url('/icons/card_green.svg');
  /* Add your image path */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  border-radius: 12px;
  /* Set the height to the viewport height or adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-container-pink {
  background-image: url('/icons/card_pink.svg');
  /* Add your image path */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  border-radius: 12px;
  /* Set the height to the viewport height or adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-container-back {
  background-image: url('/icons/grey_rotate.svg');
  /* Add your image path */
  background-size: cover;
  /* Adjust as needed */
  background-position: center;
  /* Adjust as needed */
  background-repeat: no-repeat;
  border-radius: 12px;
  /* Set the height to the viewport height or adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.flip-card {
  background-color: transparent;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-inner.is-flipped {
  transform: rotateY(180deg);
}

.flip-card-flip {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.cards:hover .card {
  background: radial-gradient(100rem circle at var(--xPos) var(--yPos), /* rgba($ciBlue, 0.4), */ transparent 15%);
}

.cards .card {
  width: 20rem;
  height: 15rem;
  background: radial-gradient(150rem circle at 0 0, /* rgba($ciBlue, 0), */ transparent 0%);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.15s;
}

.cards .card:hover {
  transform: scale(0.97);
}

.cards .card:hover::before {
  opacity: 1;
}

.cards .card::before {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  background: radial-gradient(60rem circle at var(--xPos) var(--yPos), rgba('#FFFFFF', 0.1), transparent 35%);
  opacity: 0;
  transition: all 0.15s ease-in-out;
}

.cards .card .card-content {
  background-color: #13161c;
  border-radius: inherit;
  transition: all 0.25s;
  height: calc(100% - 0.1rem);
  width: calc(100% - 0.1rem);
}

.orange_gradient_text {
  background: linear-gradient(to right, #ff4e17, #ffbc39, #ff4e17);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.purple_gradient_text {
  background: linear-gradient(to right, #6445ff, #bcaeff, #6445ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green_gradient_text {
  background: linear-gradient(to right, #d7ff01, #eeff8f, #d7ff01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink_gradient_text {
  background: linear-gradient(to right, #ad1b6b, #ff76c1, #e52b92);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Scrollbar container */
::-webkit-scrollbar {
  width: 10px; /* You can adjust the width here */
  height: 10px; /* Adjust height for horizontal scrollbars */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #1e1e1e; /* Even darker background for the track */
  border-radius: 10px; /* Optional: Adds rounded corners to the track */
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #333; /* Very dark grey for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #1e1e1e; /* Optional: Adds a border matching the track's background */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4d4d4d; /* Slightly lighter grey on hover for visual feedback */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

/* Custom scrollbar styles */
.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.grid {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.01) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 1px, transparent 1px);
}

.login-container {
  position: relative;
  top: 280px;
  height: 100%;
}

.gradient-overlay-left,
.gradient-overlay-right {
  position: absolute;
  width: 100%;
  height: 100%;
}

.gradient-overlay-left {
  background: radial-gradient(161.17% 161.17% at -2.35% -2.51%, var(--stop-color1) 0%, rgba(20, 34, 161, 0) 65.03%);
  opacity: 60%;
  left: 0;
  top: 0;
  position: absolute;
}
.gradient-overlay-right {
  background: radial-gradient(161.17% 161.17% at 102.35% 102.51%, var(--stop-color1) 0%, rgba(20, 34, 161, 0) 65.03%);
  opacity: 60%;
  right: 0;
  bottom: 0;
  position: absolute;
}

/* @media screen and (max-width: 768px) {
  .login-container {
    top: 2000px;
  }
} */

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
